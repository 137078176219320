import React from 'react';
import '../assets/styles.css';

function WP() {
  return (
    <section className="whitepaper">
      <div className="container">
        <h2>White Paper</h2>

        {/* Introduction Section */}
        <div className="whitepaper-section">
          <h3>TokAssets - Revolutionizing Investment of Real-World Assets Through Blockchain Tokenization</h3>
          <p>TokAssets introduces a groundbreaking platform for the tokenization and exchange of real-world 
          assets using the ERC-3643 tokenization standard. By leveraging this blockchain technology, TokAssets
           aims to simplify and automate the tokenization and compliance of real-world assets, driving their 
           wide adoption and enabling our investors to grow their wealth through our seamless and world-class platform.</p>
        </div>

        {/* What is Tokenization Section */}
        <div className="whitepaper-section">
          <h4>What is Tokenization?</h4>
          <p>Tokenization is the process of converting a real or digital asset into a digital token on a blockchain.
           These tokens represent a share of the underlying asset and can be traded, transferred, or held similarly 
           to traditional assets but with several advantages specific to blockchain technology.</p>
          <p>Any type of asset can be tokenized, including shares of funds or companies, real estate, commodities 
          like gold, diamonds, or watches, artworks, or intellectual property rights. A token is created on a blockchain
           to represent a specific share of the underlying asset. Tokenization allows an asset to be divided into fractions, enabling investors to buy a small part of an asset (e.g., a fraction of a building) rather than having to purchase the entire asset and avoiding the storage or management hassle.</p>
          <p>The ownership and transactions related to the tokens are recorded on the blockchain, providing transparency,
           immutability, and security. In essence, tokenization transforms traditional and real-world assets into more 
           accessible, liquid, and secure instruments through blockchain technology.</p>
          <p><strong>Warning:</strong> Always keep your private key private; never share it under any circumstances.</p>
        </div>

        {/* Abstract Section */}
        <div className="whitepaper-section">
          <h4>Abstract</h4>
          <p>TokAssets introduces a groundbreaking platform for the tokenization and exchange of real-world assets using
           the ERC-3643 tokenization standard. Our solution begins by optimizing asset management and fund processes, 
           naturally lowering costs. Tokenization significantly increases access to a broader range of investors looking 
           for new opportunities that were previously unavailable.</p>
          <p>The platform democratizes investment, allowing a wider audience to participate with a lower entry barrier. 
        The use of the ERC-3643 standard enhances liquidity, transparency, traceability, and provides greater flexibility. With near-instant payment processing and the ability to attract new investors, TokAssets is poised to revolutionize the investment and asset management landscape.</p>
        </div>

        {/* Executive Summary Section */}
        <div className="whitepaper-section">
          <h4>Executive Summary</h4>
          <p>TokAssets is a groundbreaking platform designed to revolutionize the investment of real-world assets through 
          blockchain tokenization, leveraging the ERC-3643 tokenization standard for compliance, security, and scalability. 
          Our platform enables a seamless, transparent, and efficient way to tokenize and trade a variety of real-world 
          assets, from real estate to commodities, thus democratizing investment opportunities for all types of investors.</p>
          <p><strong>Market Potential:</strong> The market for asset tokenization is projected to reach $16 trillion by 
          2030 (BCG statement), with up to $2.5 trillion addressable in investment funds alone.</p>
          <p><strong>Competitive Advantages:</strong></p>
          <ul>
            <li><strong>Compliance and Security:</strong> The ERC-3643 standard ensures full compliance with EU regulations
             and robust security features, including multi-signature wallets and regular audits.</li>
            <li><strong>Liquidity and Accessibility:</strong> Fractional ownership allows smaller investors to participate 
            in traditionally inaccessible asset classes.</li>
            <li><strong>Speed and Efficiency:</strong> Near-instant payment processing and automated compliance reduce costs
             and administrative burdens.</li>
          </ul>
          <p><strong>Fundraising Goal:</strong> Seeking to raise money from investors in the pre-sale phase to fund extensive 
          platform development (including set up of technical stack capable of sustaining milions orders per second, UI/UX, multi
           languages and on multi device coverage on Ios, Android, Windows, Mac...), marketing initiatives, strategic partnerships, 
           and to meet legal and regulatory requirements. 
          </p>
          <p>By bridging the gap between traditional and decentralized finance, TokAssets aims to make tokenization a mainstream
           financial instrument.</p>
        </div>

        {/* Introduction Section */}
        <div className="whitepaper-section">
          <h4>Introduction</h4>
          <p>In recent years, the financial industry has recognized the transformative potential of blockchain technology. At TokAssets,
           we believe that the tokenization of assets—transforming real-world assets into digital tokens—can revolutionize the asset 
           management sector and broadly impact investments for all. This process can make financial markets more efficient by simplifying 
           the value chain and lowering costs for investors.</p>
          <p>TokAssets democratizes investments by lowering entry barriers, fractionating assets, increasing liquidity, and providing 
          greater transparency and traceability. Our mission is to leverage blockchain to redefine existing financial ecosystems, 
          making them more accessible, transparent, and efficient while attracting new generation of investors with innovative types 
          of investments, near-instant payments, and increased flexibility.</p>
        </div>


        {/* Market Overview and Needs Section */}
        <div className="whitepaper-section">
          <h4>Market Overview and Needs</h4>
          <p>The market for asset tokenization is expected to grow exponentially by 2030, with a projected potential of up to $16 
          trillion across various asset classes (according to BCG). For instance, the addressable market for investment funds 
          could reach $2.5 trillion, with bond funds accounting for $250-375 billion annually.</p>
          <p>TokAssets addresses the growing need among investors to access the same kinds of investments as insiders and allows 
          asset management companies or funds to reduce internal costs and explore new distribution channels while overcoming 
          challenges related to technical transitions, legal complexities, and identifying commercial opportunities.</p>
        </div>

        {/* Challenges in the Market Section */}
        <div className="whitepaper-section">
          <h4>Challenges in the Market</h4>
          <p>Despite the recognized benefits of tokenization, many asset management companies or funds hesitate to adopt it due 
          to several barriers:</p>
          <ul>
            <li><strong>Technical Transition:</strong> Integrating blockchain into existing systems can be complex and costly.</li>
            <li><strong>Legal Framework:</strong> Navigating the regulatory environment for tokenized assets remains a challenge.</li>
            <li><strong>Low Familiarity with DeFi:</strong> There is limited understanding of what tokenization is and how tokenization
             interacts with decentralized finance (DeFi) systems.</li>
          </ul>
        </div>

        {/* Solution Section */}
        <div className="whitepaper-section">
          <h4>TokAssets’s Solution</h4>
          <p>TokAssets offers a comprehensive platform designed to overcome these barriers by providing:</p>
          <ul>
            <li><strong>Tokenization Services:</strong> Enabling the digital representation of real-world assets on the blockchain.</li>
            <li><strong>Compliance and Register Management:</strong> Offering real-time automated, blockchain-based ownership tracking, 
            fully compliant with EU legislation.</li>
            <li><strong>Distribution Platform:</strong> Facilitating the buying, selling, and redemption of tokenized assets with 
            integrated and automated KYC/AML processes.</li>
          </ul>
          <p>Our platform ensures compliance with stringent legal requirements while enhancing user flexibility and ease of use.</p>
        </div>

        {/* Value Proposition Section */}
        <div className="whitepaper-section">
          <h4>Value Proposition</h4>
          <p>TokAssets creates new investment opportunities and a more efficient, cost-effective investment environment by reducing 
          intermediaries, streamlining, and automating regulatory adherence and administrative tasks. Key value propositions of our platform include:</p>
          <ul>
            <li><strong>Reduces Intermediaries:</strong> Streamlines the investment process by minimizing the number of involved 
            parties.</li>
            <li><strong>Increases Liquidity:</strong> Enhances the liquidity of real-world assets through tokenization.</li>
            <li><strong>Offers New Investment Opportunities:</strong> Provides access to new classes of assets and reduces entry 
            barriers for investors.</li>
            <li><strong>Offers Transparency and Traceability:</strong> Ensures enhanced traceability of transactions, offering 
            full transparency.</li>
            <li><strong>Attracts New Investors:</strong> With near-instant payments and increased flexibility, TokAssets attracts
             a new generation of investors.</li>
            <li><strong>Automates Compliance:</strong> Automates compliance processes to ensure that all tokenized assets adhere 
            to relevant legal and regulatory frameworks due to the ERC-3643 standard, reducing the risk of legal challenges and 
            simplifying the compliance workflow.</li>
            <li><strong>Automates Register Management:</strong> Automates the management of asset ownership registers through 
            blockchain technology, providing real-time updates and reducing administrative burdens.</li>
            <li><strong>EVM Compatibility:</strong> Compatible with EVM blockchains, enabling seamless asset transfers and 
            integrations across different platforms.</li>
          </ul>
        </div>

        {/* Tokenomics Section */}
        <div className="whitepaper-section">
          <h4>Tokenomics</h4>
          <p>The TokAssets platform will utilize a native token, TKA, to power its ecosystem, providing various utilities and 
          incentives to its holders.</p>
          <p><strong>Token Allocation Breakdown:</strong></p>
          <ul>
            <li><strong>Pre-sale:</strong> 50% – Allocated for the presale to raise initial capital, build a seamless world-class 
            Tokenization Platform, and establish a strong community to whom we can offer the best investment opportunities.</li>
            <li><strong>Team and Founders:</strong> 20% – With a 4-year vesting period (including a 1-year cliff), ensuring market
             stability and aligning the team's incentives with long-term project success.</li>
            <li><strong>Partnerships and Advisors:</strong> 10% – With a 4-year vesting period to ensure long-term commitment and 
            alignment with strategic partners and advisors.</li>
            <li><strong>Marketing and Community Building:</strong> 10% – Allocated to fund ongoing marketing campaigns, community 
            engagement initiatives, and educational content to drive platform adoption and growth.</li>
            <li><strong>Liquidity Provision:</strong> 5% – Reserved to provide liquidity on exchanges, ensuring smooth trading 
            and reducing price volatility.</li>
            <li><strong>Reserve Fund:</strong> 5% – Set aside to cover unforeseen expenses, future development, strategic 
            acquisitions, and other operational needs.</li>
          </ul>
          <p><strong>Utility of the Token (TKA):</strong></p>
          <ul>
            <li><strong>Transaction Fees:</strong> TKA will be used for all transactions on the platform, including trading,
             payments, and service fees. A portion of the transaction fees will be burned periodically, introducing a deflationary
              mechanism to decrease the total supply over time and potentially increase the token's value.</li>
            <li><strong>Staking:</strong> TKA holders can stake their tokens to earn rewards and participate in governance voting.
             Staking incentives will be provided from a dedicated staking pool, and rewards will be distributed based on the duration
              and amount staked, fostering long-term holding and community engagement.</li>
            <li><strong>Access to Premium Features:</strong> TKA holders will gain exclusive access to certain high-value 
            investment opportunities, discounted fees, and premium services on the TokAssets platform, enhancing the value proposition
             for holding the token.</li>
          </ul>
        </div>

        {/* Technology Section */}
        <div className="whitepaper-section">
          <h4>Technology: Blockchain & ERC-3643</h4>
          <p>TokAssets will be built on a robust technological foundation, leveraging cutting-edge blockchain protocols and 
          smart contract technology.</p>
          <ul>
            <li><strong>Blockchain Protocol:</strong> The platform will be built on Ethereum and Polygon, ensuring high security,
             transparency, and scalability.</li>
            <li><strong>Smart Contracts:</strong> Automates and secures transactions and compliance on the platform, ensuring 
            transparency without intermediaries.</li>
            <li><strong>Security Features:</strong> Employs state-of-the-art security measures, including multi-signature wallets,
             encryption, and regular security audits.</li>
            <li><strong>Interoperability:</strong> Compatible with EVM blockchains, enabling seamless asset transfers and integrations
             across different platforms.</li>
          </ul>
        </div>

        {/* Team & Partnerships Section */}
        <div className="whitepaper-section">
          <h4>Team & Partnerships</h4>
          <p>The success of TokAssets is driven by a highly experienced team and strategic partnerships.</p>
          <ul>
            <li><strong>Team:</strong> Our leadership team comprises experts in blockchain technology, finance, legal, 
            and wealth management engineering.</li>
            <li><strong>Advisors:</strong> TokAssets is supported by a group of industry-leading advisors who provide 
            guidance on strategy.</li>
            <li><strong>Partnerships:</strong> We are establishing partnerships with financial institutions and regulatory
             bodies to ensure the successful implementation and growth of TokAssets.</li>
          </ul>
        </div>

        {/* Business Model Section */}
        <div className="whitepaper-section">
          <h4>Business Model</h4>
          <p>TokAssets generates revenue through several streams:</p>
          <ul>
            <li><strong>Transaction and withdrawal Fees:</strong> A small fee for buying/exchanging tokens on the platform.</li>
            <li><strong>Administrative Fees:</strong> Annual fees for register management and tokenization services.</li>
            <li><strong>Management Fees:</strong> A percentage of the interest paid on tokenized assets.</li>
          </ul>
          <p>Key cost components include salaries, platform development, technical maintenance, legal compliance, and regulatory approvals.</p>
        </div>

        {/* Roadmap Section */}
        <div className="whitepaper-section">
          <h4>Roadmap</h4>
          <p>Our roadmap outlines the key milestones for the development and launch of the TokAssets platform:</p>
          <ul>
            <li><strong>2024 Q3: Project initiation</strong> - Kick-off and project planning, development of a community on 
            social media, site web launch.</li>
            <li><strong>2024 Q4: Deployment of smart contracts</strong> - Launch on Testnet, audit, and web platform development
            including set up of a technical stack capable of sustaining millions orders per second, UI/UX, multi languages and
             on multi device coverage on Ios, Android, Windows, Mac...). Community development and partnerships</li>
            <li><strong>2025 Q1: Beta testing and regulatory approval</strong> - Conduct testing and apply for necessary approvals.
             Continue community growth and partnerships.</li>
            <li><strong>2025 Q4: CASP approval</strong> - Obtain the necessary Crypto-Asset Service Provider (CASP) approval to 
            be able to tokenized and sell this kind of assets.</li>
            <li><strong>2026 Q1: Platform Launch</strong> - Launch of the platform and the first tokenized fund.</li>
            <li><strong>2026 Q2: Expansion to Other Assets</strong> - Expand to new assets like private equity funds shares, 
            gold, diamonds, arts, real estate and clients acquisition.</li>
          </ul>
        </div>

        {/* Legal and Regulatory Framework Section */}
        <div className="whitepaper-section">
          <h4>Legal and Regulatory Framework</h4>
          <p>TokAssets operates within a complex regulatory environment, adhering to security token regulations and 
          financial service directives within the EU. We ensure compliance through:</p>
          <ul>
            <li><strong>Regulatory Readiness:</strong> Partnerships with reputable legal firms to navigate the complex 
            regulatory environment. Compliance with standards such as MICA (Markets in Crypto-Assets) regulations for 
            the EU Applications for market infrastructure licenses with relevant authorities following MICA regulation 
            (CASP: crypto-assets service provider).</li>
            <li><strong>Audits and Certifications:</strong> Regular third-party audits for security and compliance. 
            Plans to obtain certifications from relevant authorities.</li>
            <li><strong>Standardization of Tokenization:</strong> The ERC-3643 tokenization standard is a cornerstone 
            of our solution, embedding compliant information in additional layers of code.</li>
          </ul>
        </div>

        {/* Conclusion Section */}
        <div className="whitepaper-section">
          <h4>Conclusion</h4>
          <p>TokAssets is poised to be a leader in the tokenization of real-world assets, offering a secure, efficient,
           and innovative platform for asset managers, any company who needs to tokenize and our customers. By addressing
            the key challenges in the market and providing a robust solution, TokAssets aims to make tokenization a mainstream 
            approach in the financial industry and offer new investment opportunities to all our customers.</p>
          <p>For more information, please contact us at <a href="mailto:hello.tokassets@gmail.com">hello.tokassets@gmail.com</a></p>
        </div>
      </div>
    </section>
  );
}

export default WP;
