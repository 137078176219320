import React from 'react';
import '../assets/styles.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-social">
          <a href="https://x.com/TokAssets" target="_blank" rel="noopener noreferrer">
            <img src="../../twitter.png" alt="X" className="icon" />
          </a>
          <a href="https://discord.gg/gq4tA7xx" target="_blank" rel="noopener noreferrer">
            <img src="../../discord.png" alt="Discord" className="icon" /> 
          </a>
          <a href="https://www.reddit.com/r/TokAssets_TKA/" target="_blank" rel="noopener noreferrer">
            <img src="../../reddit.png" alt="Reddit" className="icon" />
          </a>
          <a href="https://t.me/+fS4xcWUzOKU4ZGM0" target="_blank" rel="noopener noreferrer">
            <img src="../../telegram.png" alt="Telegram" className="icon" />
          </a>
          <a href="https://instagram.com/tokassets" target="_blank" rel="noopener noreferrer">
            <img src="../../instagram.png" alt="Instagram" className="icon" />
          </a>
        </div>
        <div className="footer-copyright">
          <p>&copy; 2024 TokAssets. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
